.cssdf {
    top: 26px;
    left: calc(-50% + 0px);
    right: calc(50% + 0px);
    z-index: -2;
}
.stepper-active::after {
    content: ' ';
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    border: 1px solid;
    border-color: #5492C6;
    background: #e9f0f7;
}
