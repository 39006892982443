.note {
    position: relative;
    overflow: hidden;
    -moz-border-radius: 5px 0 5px 5px;
}

.note:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: #dee3ef;
    /* Firefox 3.0 damage limitation */
    display: block;
    width: 0;
    border-width: 20px;
    border-color: transparent #e9f0f7 #e9f0f7 transparent;
    -moz-border-radius: 0 0 0 5px;
    border-radius: 5px 0 0 0;
}

.pin {
    content: '';
    position: absolute;
    color: transparent;
    top: 20px;
    right: 20px;
    background-image: url('../../../assets/img/pin.svg');
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 20px; */
    width: 32px;
}
