/* Inner timeline styles */
.__inner-list ul:not(.replies) li:not(.reply) {
    position: relative;
}

/* Line */
.__inner-list ul:not(.replies) li:not(.reply)::before {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: transparent;
    transform: translateX(-4px);
    padding-top: 8px;
    margin-top: 12px;
    border-left: 1px dashed #000;
}

/* Bullets */
.__inner-list ul:not(.replies) li:not(.reply)::after {
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    border: 1px solid #333e5d;
    transform: translateX(-7.5px);
    @apply bg-blue-body;
    @apply rounded-full;
    @apply mt-2;
}
.__inner-list ul:not(.replies).remarks li:not(.reply):first-child::after {
    border: none;
    width: 9px;
    height: 9px;
    transform: translateX(-7.5px);
    @apply bg-blue-status;
}

/* Last item's bullet */
.__inner-list ul:not(.replies) li:not(.reply):last-child::before {
    content: none;
}

/* Outer timeline styles */
.__outer-list li:not(.reply):not(.approverList) {
    margin-left: 20px;
}

/* Lines */
.__outer-list li:not(.reply):not(.approverList)::before {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #707070;
    /* transform: translateX(-0.05rem); */
    padding-top: 8px;
    margin-top: 36px;
}

/* Bullets */
.__outer-list li:not(.reply):not(.approverList)::after {
    content: ' ';
    position: absolute;
    width: 14px;
    height: 14px;
    left: 0;
    top: 0;
    background: #abb9c4;
    transform: translateX(-0.5rem);
    z-index: 1000;
    @apply rounded-full;
    @apply mt-7;
}

.__outer-list li:not(.reply):not(.approverList).waiting::after {
    background: #abb9c4;
}

.__outer-list li:not(.reply):not(.approverList).pending::after {
    background: #abb9c4;
}

.__outer-list li:not(.reply):not(.approverList).pending.current::after {
    background: #fac72d;
    box-shadow: 0px 0px 0px 4px rgba(250, 199, 45, 0.5);
}

.__outer-list li:not(.reply):not(.approverList).returned::after {
    background: #4288cb;
}

.__outer-list li:not(.reply):not(.approverList).returned.current::after {
    box-shadow: 0px 0px 0px 4px rgba(66, 136, 203, 0.5);
}

.__outer-list li:not(.reply):not(.approverList).approved::after {
    background: #63ad26;
}

.__outer-list li:not(.reply):not(.approverList).approved.current::after {
    box-shadow: 0px 0px 0px 4px rgba(99, 173, 38, 0.5);
}

.__outer-list li:not(.reply):not(.approverList).rejected::after {
    background: #ff080a;
}

.__outer-list li:not(.reply):not(.approverList).rejected.current::after {
    box-shadow: 0px 0px 0px 4px rgba(255, 8, 10, 0.5);
}

.__outer-list li:not(.reply):not(.approverList).cancelled::after {
    background: #afafaf;
}

.__outer-list li:not(.reply):not(.approverList).cancelled.current::after {
    box-shadow: 0px 0px 0px 4px rgba(175, 175, 175, 0.5);
}

.__outer-list li:not(.reply):not(.approverList).withdrawn::after {
    background: #ff7948;
}

.__outer-list li:not(.reply):not(.approverList).withdrawn.current::after {
    box-shadow: 0px 0px 0px 4px rgba(255, 121, 72, 0.5);
}

/* Last item */
.__outer-list li:not(.reply):not(.approverList):last-child::before {
    content: none;
}

.__sub-approval::before {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 18px;
    background-color: #707070;
    transform: translate(-0.30rem, -0.60rem);
}
.__sub-approval-marker::before {
    content: ' ';
    position: absolute;
    width: 8px;
    height: 1px;
    background-color: #707070;
    transform: translate(-0.30rem, 0.5rem);
}