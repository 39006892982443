.notifications {
    @apply inline-flex;
    width: 80px;
    height: 80px;
    @apply items-center;
    @apply justify-center;
    border-left: 1px solid #e2e2e2;
}

.bell {
    width: 24px;
    height: 24px;
}
.bell-mini {
    width: 18px;
    height: 18px;
}
.wrapper {
    width: 340px;
    height: 512px;
    position: absolute;
    background-color: white;
    top: 82px;
    right: 1%;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 999999999;
}
.list-content {
    overflow-y: auto;
}
.active::after {
    content: ' ';
    width: 12px;
    height: 12px;
    @apply bg-blue-dark;
    @apply rounded-full;
    position: absolute;
    top: 50%;
    right: 5%;
}
.unread-item {
    width: 12px;
    height: 12px;
    max-width: 12px;
    max-height: 12px;
    @apply bg-blue-dark;
    @apply rounded-full;
}
.unread-bell {
    width: 10px;
    height: 10px;
    top: 14px;
    right: 30%;
    @apply bg-red;
    @apply absolute;
    @apply rounded;
}

/* Responsive */
@media only screen and (max-width: 520px) {
    .wrapper {
        width: 100%;
        height: 480px;
        left: 0;
        top: 80px;
    }
}
