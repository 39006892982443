.login-bg {
    width: 60%;
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
}

@media only screen and (max-width: 520px) {
    .login-bg {
        width: 120%;
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}
