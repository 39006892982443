select {
    /* reset */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../assets/img/ic_chevron-down.svg) no-repeat white;
    background-position: 98% 50%;
    background-size: 13px 6px;
}

.__select_no_bg {
    background: url(../../assets/img/ic_chevron-down.svg) no-repeat transparent;
    background-position: 85% 50%;
    background-size: 13px 6px;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
