.dropdown {
    position: relative;
    font-family: 'FS Elliot Pro Regular';
}

.dropdown-content {
    display: none;
    position: absolute;
    align-items: center;
    right: 0;
    background-color: white;
    width: 100%;
    z-index: 1;
    top: 100%;
}

.dropdown:hover .dropdown-content {
    display: block;
    width: auto;
}

.dropdown-content a {
    text-decoration: none;
    display: block;
}

.dropdown-button {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    transition-duration: 0.4s;
    border: none;
    text-align: center;
}

.dropdown-button:hover {
    cursor: pointer;
}

.dropdown-button:disabled {
    cursor: not-allowed;
}
