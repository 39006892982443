.branch {
    width: 100%;
    height: auto;
    position: relative;
}
.branch::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%; /* or 100px */
    background: #555;
    border: none;
}
.branch:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0px;
}
.branch:last-child > .twig {
    margin-top: 0px;
}
.branch:last-child::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 50px; /* or 100px */
    background: #555;
    border: none;
}
.twig {
    width: 29px;
    height: 0.5px;
    background: #555;
    margin-top: 50px;
}

@media only screen and (max-width: 768px) {
    .branch::after {
        width: 0px;
    }
    .branch:last-child::after {
        width: 0px;
    }
    .twig {
        width: 0px;
    }
}
