.unreset {
    a {
        @apply text-blue;
        @apply underline;
    }
    p {
        @apply my-4;
        @apply text-sm;
        @apply font-regular;
    }

    blockquote,
    figure {
        @apply my-4;
        @apply mx-10;
    }

    hr {
        @apply border;
    }

    h1 {
        @apply text-4xl;
        @apply font-bold;
        @apply my-2;
        @apply font-regular;
    }

    h2 {
        @apply text-2xl;
        @apply font-bold;
        @apply my-3;
        @apply font-regular;
    }

    h3 {
        @apply text-lg;
        @apply font-bold;
        @apply my-4;
        @apply font-regular;
    }

    h4 {
        @apply text-base;
        @apply font-bold;
        @apply my-5;
        @apply font-regular;
    }

    h5 {
        @apply text-sm;
        @apply font-bold;
        @apply my-6;
        @apply font-regular;
    }

    h6 {
        @apply text-xs;
        @apply font-bold;
        @apply my-10;
        @apply font-regular;
    }

    article,
    aside,
    nav,
    section {
        h1 {
            @apply text-2xl;
            @apply font-bold;
            @apply my-3;
            @apply font-regular;
        }

        article,
        aside,
        nav,
        section {
            h1 {
                @apply text-lg;
                @apply font-bold;
                @apply my-4;
                @apply font-regular;
            }

            article,
            aside,
            nav,
            section {
                h1 {
                    @apply text-base;
                    @apply font-bold;
                    @apply my-5;
                    @apply font-regular;
                }

                article,
                aside,
                nav,
                section {
                    h1 {
                        @apply text-sm;
                        @apply font-bold;
                        @apply my-6;
                        @apply font-regular;
                    }

                    article,
                    aside,
                    nav,
                    section {
                        h1 {
                            @apply text-xs;
                            @apply font-bold;
                            @apply my-10;
                            @apply font-regular;
                        }
                    }
                }
            }
        }
    }

    ul,
    menu {
        // should be my-4 for proper reset, but thats ugly af
        @apply list-disc;
        @apply my-1;
        @apply pl-10;
        @apply text-sm;
        @apply font-regular;
    }

    ol {
        @apply list-decimal;
        @apply my-4;
        @apply pl-10;
        @apply text-sm;
        @apply font-regular;
    }

    ul,
    ol {
        ul {
            list-style-type: circle;
        }

        ul,
        ol {
            ul {
                list-style-type: square;
            }
        }
    }

    dd {
        @apply pl-10;
    }

    dl {
        @apply my-4;
    }

    ul,
    ol,
    menu,
    dl {
        ul,
        ol,
        menu,
        dl {
            margin: 0;
        }
    }

    legend {
        @apply py-0;
        @apply px-1;
    }

    fieldset {
        @apply my-0;
        @apply mx-1;
        @apply pt-0;
        @apply px-1;
        @apply pb-2;
    }

    b,
    strong {
        font-weight: bold;
    }

    pre {
        @apply my-4;
    }
}

/*
 * CKEditor 5 (v26.0.0) content styles.
 * Generated on Wed, 03 Mar 2021 08:21:28 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */
:root {
    --ck-border-radius: 5px;
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-todo-list-checkmark-size: 16px;
}
.ck-content {
    .text-tiny {
        font-size: 0.7em;
    }

    .text-small {
        font-size: 0.85em;
    }

    .text-big {
        font-size: 1.4em;
    }

    .text-huge {
        font-size: 1.8em;
    }

    .marker-yellow {
        background-color: var(--ck-highlight-marker-yellow);
    }

    .marker-green {
        background-color: var(--ck-highlight-marker-green);
    }

    .marker-pink {
        background-color: var(--ck-highlight-marker-pink);
    }

    .marker-blue {
        background-color: var(--ck-highlight-marker-blue);
    }

    .pen-red {
        color: var(--ck-highlight-pen-red);
        background-color: transparent;
    }

    .pen-green {
        color: var(--ck-highlight-pen-green);
        background-color: transparent;
    }

    .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 1em auto;

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            min-width: 50px;
        }

        > figcaption {
            display: table-caption;
            caption-side: bottom;
            word-break: break-word;
            color: hsl(0, 0%, 20%);
            background-color: hsl(0, 0%, 97%);
            padding: 0.6em;
            font-size: 0.75em;
            outline-offset: -1px;
        }

        &.image_resized {
            max-width: 100%;
            display: block;
            box-sizing: border-box;

            img {
                width: 100%;
            }

            > figcaption {
                display: block;
            }
        }
    }

    hr {
        margin: 15px 0;
        height: 4px;
        background: hsl(0, 0%, 87%);
        border: 0;
    }

    .image-style-side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
        max-width: 50%;
    }

    .image-style-align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
    }

    .image-style-align-center {
        margin-left: auto;
        margin-right: auto;
    }

    .image-style-align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
    }

    blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        margin-right: 0;
        font-style: italic;
        border-left: solid 5px hsl(0, 0%, 80%);
    }

    &[dir='rtl'] blockquote {
        border-left: 0;
        border-right: solid 5px hsl(0, 0%, 80%);
    }

    code {
        background-color: hsla(0, 0%, 78%, 0.3);
        padding: 0.15em;
        border-radius: 2px;
    }

    .table {
        margin: 1em auto;
        display: table;

        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            height: 100%;
            border: 1px double hsl(0, 0%, 70%);

            td {
                min-width: 2em;
                padding: 0.4em;
                border: 1px solid hsl(0, 0%, 75%);
                @apply text-sm;
            }

            th {
                min-width: 2em;
                padding: 0.4em;
                border: 1px solid hsl(0, 0%, 75%);
                font-weight: bold;
                background: hsla(0, 0%, 0%, 5%);
                @apply text-sm;
            }
        }
    }

    &[dir='rtl'] .table th {
        text-align: right;
    }

    &[dir='ltr'] .table th {
        text-align: left;
    }

    .page-break {
        position: relative;
        clear: both;
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            position: absolute;
            border-bottom: 2px dashed hsl(0, 0%, 77%);
            width: 100%;
        }
    }

    .page-break__label {
        position: relative;
        z-index: 1;
        padding: 0.3em 0.6em;
        display: block;
        text-transform: uppercase;
        border: 1px solid hsl(0, 0%, 77%);
        border-radius: 2px;
        font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
        font-size: 0.75em;
        font-weight: bold;
        color: hsl(0, 0%, 20%);
        background: hsl(0, 0%, 100%);
        box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .media {
        clear: both;
        margin: 1em 0;
        display: block;
        min-width: 15em;
    }

    .todo-list {
        list-style: none;

        li {
            margin-bottom: 5px;

            .todo-list {
                margin-top: 5px;
            }
        }

        .todo-list__label {
            > input {
                -webkit-appearance: none;
                display: inline-block;
                position: relative;
                width: var(--ck-todo-list-checkmark-size);
                height: var(--ck-todo-list-checkmark-size);
                vertical-align: middle;
                border: 0;
                left: -25px;
                margin-right: -15px;
                right: 0;
                margin-left: 0;

                &::before {
                    display: block;
                    position: absolute;
                    box-sizing: border-box;
                    content: '';
                    width: 100%;
                    height: 100%;
                    border: 1px solid hsl(0, 0%, 20%);
                    border-radius: 2px;
                    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
                }

                &::after {
                    display: block;
                    position: absolute;
                    box-sizing: content-box;
                    pointer-events: none;
                    content: '';
                    left: calc(var(--ck-todo-list-checkmark-size) / 3);
                    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
                    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
                    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
                    border-style: solid;
                    border-color: transparent;
                    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
                        calc(var(--ck-todo-list-checkmark-size) / 8) 0;
                    transform: rotate(45deg);
                }

                &[checked] {
                    &::before {
                        background: hsl(126, 64%, 41%);
                        border-color: hsl(126, 64%, 41%);
                    }

                    &::after {
                        border-color: hsl(0, 0%, 100%);
                    }
                }
            }

            .todo-list__label__description {
                vertical-align: middle;
            }
        }
    }

    .raw-html-embed {
        margin: 1em auto;
        min-width: 15em;
        font-style: normal;
    }

    pre {
        padding: 1em;
        color: hsl(0, 0%, 20.8%);
        background: hsla(0, 0%, 78%, 0.3);
        border: 1px solid hsl(0, 0%, 77%);
        border-radius: 2px;
        text-align: left;
        direction: ltr;
        tab-size: 4;
        white-space: pre-wrap;
        font-style: normal;
        min-width: 200px;

        code {
            background: unset;
            padding: 0;
            border-radius: 0;
        }
    }

    .mention {
        background: var(--ck-color-mention-background);
        color: var(--ck-color-mention-text);
    }
}
@media print {
    .ck-content .page-break {
        padding: 0;

        &::after {
            display: none;
        }
    }
}
