.memo-feed {
    scrollbar-width: thin;
    scrollbar-color: #c6c6c6 transparent;
    min-height: 600px;
    max-height: 600px;
}
.memo-feed::-webkit-scrollbar {
    width: 7px;
}
.memo-feed::-webkit-scrollbar:hover {
    width: 10px;
}
.memo-feed::-webkit-scrollbar-track {
    background: transparent;
}
.memo-feed::-webkit-scrollbar-thumb {
    background-color: #c6c6c6;
    border-radius: 0.5em;
}
.memo-feed:hover::-webkit-scrollbar-thumb {
    background-color: #afaeae;
    border-radius: 0.5em;
    width: 10px;
}
