/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #fff;
    color: #333e5d;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    white-space: nowrap;

    /* Position the tooltip text */
    position: absolute;
    z-index: 20;
    top: 100%;
    left: 0%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    /* left: 50%; */
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Tooltip text */
.tooltip .tooltipleft {
    visibility: hidden;
    background-color: #fff;
    color: #333e5d;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    /* white-space: nowrap; */

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 150%;
    right: 0%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltipleft::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 95%;
    /* margin-right: -5px; */
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipleft {
    visibility: visible;
    opacity: 1;
}
