@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: "FS Elliot Pro Regular";
  src: url('./assets/fonts/FS-Elliot-Pro-Regular.otf')
}
@font-face{
  font-family: "FS Elliot Pro Bold";
  src: url('./assets/fonts/FS-Elliot-Pro-Bold.otf');
};

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

html, body {
  height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F8FC;
}
.react-grid-item.react-grid-placeholder {
  background: #4291ff54;
  border: 2px solid #1352AB;
  opacity: 1;
  border-radius: 0.25rem;
}
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c6c6c6 transparent;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}
.custom-scrollbar::-webkit-scrollbar:hover {
  width: 10px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 0.5em;
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #afaeae;
  border-radius: 0.5em;
  width: 10px;
}

.component--expanded {
  animation-name: componentAnimation;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}
.component__contents--expanded {
  animation-name: componentContentsAnimation;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}
.component--collapsed {
  animation-name: componentCollapseAnimation;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}
.component__contents--collapsed {
  animation-name: componentCollapseContentsAnimation;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}
.wrap-links {
  word-wrap: break-word;
}

.border-dashed-custom-blue {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(84, 146, 198)' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='33' stroke-linecap='round'/%3e%3c/svg%3e");
}
@keyframes shine {
  to {
    background-position: 250px 0;
  }
}

.skeleton-loader {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    rgba(0, 0, 0, 0.1);
  background-position: -250px 0;
  background-repeat: repeat-y;
  background-size: auto;
  animation: shine 2s infinite;
}
